import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Input from '@mui/material/Input';
import { useTheme } from '@mui/material/styles';
import countBy from 'lodash/countBy';
import groupBy from 'lodash/groupBy';
import React, { useState } from 'react';
import { useV1Socket } from '../hooks/useV1Socket';
import { selectSpeakersMap, selectStatus } from '../selectors/legacy-conversation';
import { selectSpeakerListened } from '../selectors/scribe';
import { selectAvaId } from '../selectors/userProfile';
import { useAppSelector } from '../store/store';
import ParticipantAvatar from './ParticipantAvatar';
const ParticipantList = (props) => {
    var _a, _b;
    const theme = useTheme();
    const { displayShortcuts, maxSpeaker, scribe, size } = props;
    const avaId = useAppSelector(selectAvaId);
    const speakerListened = useAppSelector(selectSpeakerListened);
    const [socket] = useV1Socket();
    const status = useAppSelector(selectStatus);
    const speakers = useAppSelector(selectSpeakersMap);
    const statusSpeakers = status === null || status === void 0 ? void 0 : status.speakers;
    const [enteringNewTranscriptOwner, setEnteringNewTranscriptOwner] = useState(false);
    const [newTranscriptOwnerName, setNewTranscriptOwnerName] = useState('');
    const addTranscriptOwner = () => {
        setNewTranscriptOwnerName('');
        toggleTranscriptOwner();
        if (!socket) {
            return;
        }
        if (!newTranscriptOwnerName) {
            return;
        }
        newTranscriptOwnerName.split(',').forEach((userName) => {
            socket.send(JSON.stringify({
                type: 'add-transcript-owner',
                userName,
            }));
        });
    };
    const handleTranscriptOwnerChange = (e) => {
        setNewTranscriptOwnerName(e.target.value);
    };
    const toggleTranscriptOwner = () => {
        setNewTranscriptOwnerName('');
        setEnteringNewTranscriptOwner(!enteringNewTranscriptOwner);
    };
    const isHost = !!status.host && status.host.avaId === avaId;
    const transcriptOwners = statusSpeakers || (status.transcriptOwners || []).map((id) => speakers[id] || { id });
    const scribeParticipants = (status.participants || []).filter((participant) => participant.scribe);
    const backgroundColor = theme.palette.secondary[theme.palette.mode];
    const counts = countBy(status.participants, 'avaId');
    const scribeStatus = (_a = status === null || status === void 0 ? void 0 : status.scribe) === null || _a === void 0 ? void 0 : _a.status;
    const currentUserIsScribe = scribeParticipants.find((scribe) => scribe.avaId === avaId);
    return (React.createElement("div", { style: {
            height: 52,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'row',
            minWidth: 'fit-content',
        } },
        React.createElement("div", { style: {
                overflow: 'scroll',
                display: 'flex',
                paddingBottom: 17,
                boxSizing: 'content-box',
                height: 52,
            }, className: "invisible-scroll" },
            scribeStatus === 'pending' && (React.createElement(React.Fragment, null,
                React.createElement("div", { style: { flexGrow: 1 } }),
                React.createElement(ParticipantAvatar, { currentUserIsHost: isHost, isScribe: true, numberOfConnections: true, participant: {}, scribeStatus: "pending" }))),
            scribeParticipants.length > 0 && scribeStatus !== 'leaving' && (React.createElement(React.Fragment, null,
                React.createElement("div", { style: { flexGrow: 1 } }),
                Object.values(groupBy(scribeParticipants, 'id'))
                    .map((ar) => ar[0])
                    .map((participant) => (React.createElement("div", { key: `${participant.id}` },
                    React.createElement(ParticipantAvatar, { currentUserIsHost: isHost, currentUserIsScribe: currentUserIsScribe, isScribe: true, numberOfConnections: counts[participant.id], participant: participant, scribeStatus: scribeStatus, size: size })))),
                React.createElement("div", { style: { flexGrow: 1 } }))),
            ((_b = status.participants) === null || _b === void 0 ? void 0 : _b.length) > 1 &&
                transcriptOwners.map((participant, index) => (React.createElement("div", { key: participant.avaId, style: { marginLeft: 2, marginRight: 2 } }, index < (maxSpeaker || 10000) && (scribe || counts[participant.avaId || participant.id] > 0) && (React.createElement(React.Fragment, null,
                    React.createElement(ParticipantAvatar, { audio: (status.audioStreams || []).find((as) => as.avaId === participant.avaId) && true, canEdit: scribe, canMute: isHost || scribe, compact: true, currentUserIsHost: isHost, currentUserIsScribe: currentUserIsScribe, index: index, listened: participant.avaId === speakerListened, muted: !(status.audioStreams || []).find((as) => as.avaId === participant.avaId && !as.muted), numberOfConnections: counts[participant.avaId || participant.id], participant: participant, size: size }),
                    displayShortcuts && (React.createElement(Avatar, { "aria-label": "Recipe", style: {
                            margin: 6,
                            position: 'absolute',
                            top: 15,
                            marginLeft: 15,
                            height: 25,
                            width: 25,
                            backgroundColor: 'rgba(70, 70, 70, 0.8)',
                            color: 'cornsilk',
                        } }, index <= 8 ? index + 1 : `C${index + 1 - 9}`)),
                    displayShortcuts && (React.createElement("div", { "aria-label": "Recipe", style: {
                            margin: 6,
                            position: 'absolute',
                            top: 40,
                            padding: 2,
                            marginLeft: -5,
                            wordBreak: 'break-word',
                            maxWidth: 55,
                            borderRadius: 7,
                            backgroundColor: 'rgba(70, 70, 70, 0.8)',
                            fontSize: 12,
                            color: 'cornsilk',
                        } }, participant.userName)))))))),
        scribe && transcriptOwners.length > 0 && !enteringNewTranscriptOwner && (React.createElement(Card, { style: {
                backgroundColor,
            } },
            React.createElement(Button, { onClick: () => toggleTranscriptOwner(), style: {
                    width: 50,
                    height: 52,
                    padding: 'inherit',
                    fontSize: 30,
                } }, "+"))),
        scribe && transcriptOwners.length > 0 && enteringNewTranscriptOwner && (React.createElement(Card, { style: {
                backgroundColor,
                display: 'flex',
                flexDirection: 'column',
                height: 75,
            } },
            React.createElement("form", null,
                React.createElement(Input, { value: newTranscriptOwnerName, onChange: handleTranscriptOwnerChange, autoFocus: true, style: {
                        height: 25,
                        flexGrow: 1,
                    } }),
                React.createElement("div", { style: {
                        display: 'flex',
                        flexDirection: 'row',
                    } },
                    React.createElement(Button, { onClick: () => toggleTranscriptOwner(), style: {
                            flexGrow: 1,
                        } }, "Cancel"),
                    React.createElement(Button, { onClick: () => addTranscriptOwner(), type: "submit", style: {
                            flexGrow: 1,
                        }, variant: "contained" }, "Create")))))));
};
export default ParticipantList;
