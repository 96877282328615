import AddLinkIcon from '@mui/icons-material/AddLink';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCopyInviteLink } from '../../hooks/useCopyInviteLink';
const useStyles = makeStyles((theme) => ({
    root: {
        width: 48,
        minWidth: 48,
        height: 48,
        border: `1px solid ${theme.palette.ava.grey7}`,
        borderRadius: '50%',
    },
}));
export function ConvoV2ConversationLinkButton(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { copyInviteLink } = useCopyInviteLink();
    return (React.createElement(Button, { className: classNames(classes.root, props.className), title: t('ccMode.inviteCard.copyLink'), onClick: copyInviteLink },
        React.createElement(AddLinkIcon, { width: 24, height: 24 })));
}
