import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import { Collapse, List, ListItemIcon } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import Toggle from 'react-toggled';
import availableLangs from '../../../assets/langs.json';
import { setLangSelected } from '../../actions';
import useRecallAIManager from '../../hooks/useRecallAIManager';
import { selectSpeechLang } from '../../selectors/legacy-conversation';
import { selectRecallAIStatus } from '../../selectors/recallAI';
import { maybeRestartRecording } from '../../store/slices/audioV2';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { canShowFlagEmoji } from '../../utils/platform';
import { EmptyIcon, styles } from './SidebarHelpers';
const useStyles = makeStyles(styles);
const LangSelector = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const speechLang = useAppSelector(selectSpeechLang);
    const recallAIStatus = useAppSelector(selectRecallAIStatus);
    const recallAIManager = useRecallAIManager();
    const dispatch = useAppDispatch();
    const selectLang = (lang) => {
        localStorage.setItem('speechLang', lang);
        dispatch(setLangSelected(lang));
        dispatch(maybeRestartRecording());
    };
    const langs = [];
    Object.keys(availableLangs).forEach((key) => {
        langs.push({
            value: availableLangs[key].languageCode,
            key,
            flagCode: availableLangs[key].flagCode,
        });
    });
    useEffect(() => {
        if (recallAIStatus === 'CAPTIONING') {
            recallAIManager === null || recallAIManager === void 0 ? void 0 : recallAIManager.handleChangeLang(speechLang);
        }
    }, [speechLang]);
    const lang = langs.find((l) => l.value === speechLang);
    const currentFlagCode = lang && lang.flagCode;
    return (React.createElement(Toggle, null, ({ on, getTogglerProps, setOff }) => (React.createElement("div", { style: {
            display: 'flex',
            flexDirection: 'column',
        } },
        React.createElement("div", null,
            React.createElement(ListItemButton, Object.assign({ key: "lang_selected" }, getTogglerProps()),
                React.createElement(ListItemIcon, null,
                    React.createElement(TextsmsOutlinedIcon, null)),
                React.createElement(ListItemText, { style: { paddingRight: 0 } },
                    t('micSettings.chooseLang'),
                    React.createElement("br", null),
                    currentFlagCode && (React.createElement(ReactCountryFlag, { style: { marginRight: 5 }, svg: !canShowFlagEmoji(), countryCode: currentFlagCode })),
                    React.createElement("b", { style: { fontWeight: 600 } }, lang === null || lang === void 0 ? void 0 : lang.key)),
                React.createElement(ListItemIcon, { style: { marginRight: 0, minWidth: 0 } }, on ? React.createElement(ExpandLessOutlinedIcon, null) : React.createElement(ExpandMoreOutlinedIcon, null)))),
        React.createElement(Collapse, { in: on },
            React.createElement(List, null, langs.map((lang_) => (React.createElement(ListItemButton, { key: lang_.value, style: {
                    display: lang === lang_ ? 'none' : undefined,
                } },
                React.createElement(EmptyIcon, null),
                React.createElement(ListItemText, { classes: { root: classes.listItemText }, onClick: () => {
                        selectLang(lang_.value);
                        setOff();
                    } },
                    React.createElement(ReactCountryFlag, { style: { marginRight: 5 }, svg: !canShowFlagEmoji(), countryCode: lang_.flagCode }),
                    lang_.key))))))))));
};
export default LangSelector;
