export const isPremiumCaptions = (status, avaId) => {
    return (!!status &&
        !!((status.audioStreams &&
            status.audioStreams.findIndex((elem) => elem.usePaidASR && elem.avaId === avaId) !== -1) ||
            (status.asr && status.asr.googleCloud)));
};
export const isUnlimitedPaidASR = (subscription, features) => {
    const ongoingSubscription = subscription === null || subscription === void 0 ? void 0 : subscription.ongoingSubscription;
    const fromFlag = features['google-cloud-asr'];
    const fromSubscription = !!ongoingSubscription &&
        ongoingSubscription !== 'Free' &&
        ongoingSubscription !== 'Freedom' &&
        (subscription === null || subscription === void 0 ? void 0 : subscription.ongoingSubscriptionSubtype) !== 'Lite';
    return fromFlag || fromSubscription;
};
const pendingScribe = (status) => !!status && !!status.scribe && status.scribe.status === 'pending';
export const hasScribe = (status) => !!status && !!status.scribe && status.scribe.status === 'ongoing';
export const getScribes = (status) => {
    if (!status || !status.participants)
        return [];
    return status.participants.filter((participant) => participant.scribe);
};
export const isScribeRequestor = (status, avaId) => !!status && !!status.scribe && !!status.scribe.requestor && !!avaId && avaId === status.scribe.requestor;
export const hasRequestedScribe = (status, avaId) => (hasScribe(status) || pendingScribe(status)) && isScribeRequestor(status, avaId);
export const isHost = (status, avaId) => !!status && !!status.host && status.host.avaId === avaId;
export const getPlanType = (subscription) => {
    const { ongoingSubscription, ongoingSubscriptionSubtype } = subscription;
    const subscriptionLabel = `${getValueOrEmptyString(ongoingSubscription)} ${getValueOrEmptyString(ongoingSubscriptionSubtype)}`;
    if (subscriptionLabel.includes('Freedom')) {
        return subscriptionLabel.replace('Freedom', 'Community').trim();
    }
    else if (subscriptionLabel.endsWith('Lite')) {
        return 'Free';
    }
    return subscriptionLabel.trim();
};
export const isBoostEnabled = (subscription) => {
    if (!subscription) {
        return false;
    }
    const { ongoingSubscription, ongoingSubscriptionSubtype } = subscription;
    return ongoingSubscription && ongoingSubscription !== 'Free' && ongoingSubscriptionSubtype !== 'Lite';
};
const getValueOrEmptyString = (value) => value || '';
