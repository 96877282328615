import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { scribeCreateTranscript, scribeDeselectTranscriptWord } from '../../../actions';
import TtsButton from '../../../modules/textToSpeech/TtsButton';
import { selectAudioV2Status } from '../../../selectors/audioV2';
import { selectRecallAIManager, selectRecallAIStatus } from '../../../selectors/recallAI';
import { selectIsUsingTts, selectTtsBestVoice } from '../../../selectors/textToSpeech';
import { selectElectronCaptionMode, selectFontSize } from '../../../selectors/ui';
import { selectAvaId } from '../../../selectors/userProfile';
import { RecordingStatus } from '../../../store/slices/audioV2';
import { startRecording, stopRecording } from '../../../store/slices/audioV2';
import { setErrorAndReset } from '../../../store/slices/textToSpeech';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { attemptSpeakWithVoice } from '../../../utils/textToSpeech';
import useStyles from './KeyboardInput.styles';
const KeyboardInput = ({ onCloseKeyboard }) => {
    const fontSize = useAppSelector(selectFontSize);
    const classes = useStyles({ fontSize });
    const { t } = useTranslation();
    const avaId = useAppSelector(selectAvaId);
    const recording = useAppSelector(selectAudioV2Status) === RecordingStatus.RECORDING;
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const recallAIStatus = useAppSelector(selectRecallAIStatus);
    const recallAIManager = useAppSelector(selectRecallAIManager);
    const dispatch = useAppDispatch();
    const ttsBestVoice = useAppSelector(selectTtsBestVoice);
    const isUsingTts = useAppSelector(selectIsUsingTts);
    const volumeRef = useRef();
    const [showErrorOnce, setShowErrorOnce] = useState(false);
    const [message, setMessage] = useState('');
    const sendMessage = () => {
        dispatch(scribeDeselectTranscriptWord());
        dispatch(scribeCreateTranscript(undefined, message, avaId));
        dispatch(scribeDeselectTranscriptWord());
        setMessage('');
    };
    const onSynthesizeSpeech = (event) => {
        if (!ttsBestVoice)
            return;
        event.preventDefault();
        event.stopPropagation();
        sendMessage();
        if (recallAIStatus === 'CAPTIONING') {
            recallAIManager === null || recallAIManager === void 0 ? void 0 : recallAIManager.handleTextToSpeech(message);
        }
        else {
            attemptSpeakWithVoice({
                message,
                recording,
                startRecord: () => dispatch(startRecording()),
                stopRecord: () => dispatch(stopRecording()),
                voice: ttsBestVoice,
            });
        }
    };
    const handleSend = useCallback((e) => {
        if (!isUsingTts) {
            sendMessage();
        }
        else {
            onSynthesizeSpeech(e);
        }
    }, [isUsingTts, message]);
    useEffect(() => {
        if (electronCaptionMode && window.electronIPC) {
            window.electronIPC.invokeGetVolume().then((volume) => {
                volumeRef.current = volume;
            });
        }
    }, []);
    useEffect(() => {
        if (volumeRef.current !== undefined &&
            volumeRef.current <= 50 &&
            isUsingTts &&
            message.length > 0 &&
            !showErrorOnce) {
            dispatch(setErrorAndReset('unableToHear'));
            setShowErrorOnce(true);
        }
    }, [isUsingTts, message]);
    return (React.createElement("div", { className: classes.root },
        React.createElement("section", { className: classes.inputContainer },
            React.createElement("button", { className: classes.closeKeyboardInputButton, onClick: onCloseKeyboard },
                React.createElement(KeyboardArrowUpIcon, { className: classes.keyboardArrowUpIcon })),
            React.createElement("div", { className: classes.messageContainer },
                React.createElement("textarea", { className: classes.input, rows: "1", placeholder: t('conversation.typeTranscriptHere'), value: message, onChange: (event) => setMessage(event.target.value), onKeyDown: (event) => {
                        if (['Enter'].includes(event.key)) {
                            // User is pressing shift + enter, allow the default action of
                            // adding another line and don't send a message.
                            if (!event.shiftKey) {
                                event.preventDefault();
                                handleSend(event);
                            }
                        }
                    }, spellCheck: false, autoFocus: true })),
            React.createElement("div", { className: classes.sendContainer },
                React.createElement(TtsButton, null),
                React.createElement(Tooltip, { classes: { tooltip: classes.tooltip, tooltipPlacementTop: classes.sendTooltipPlacementTop }, title: isUsingTts ? t('ccMode.keyboard.sendAsVoiceMessage') : t('ccMode.keyboard.tooltip.send'), placement: "top", disableInteractive: true },
                    React.createElement(IconButton, { className: classes.sendButton, onClick: handleSend, size: "large" },
                        React.createElement(SendIcon, { className: classes.sendIcon })))))));
};
export default KeyboardInput;
