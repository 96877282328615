import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useAutoScroll } from '../../hooks/useAutoScroll';
import { selectAllTranscripts, selectTotalTranscriptCount, selectTranscripts, } from '../../selectors/legacy-conversation';
import { useAppSelector } from '../../store/store';
import { ConvoV2FollowTranscriptButton } from './ConvoV2FollowTranscriptButton';
import { ConvoV2TranscriptItem } from './ConvoV2TranscriptItem';
const useStyles = makeStyles(() => ({
    root: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        width: '100%',
    },
    list: {
        display: 'flex',
        overflow: 'auto',
        scrollBehavior: 'smooth',
        flexGrow: 2,
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        WebkitScrollbar: {
            WebkitAppearance: 'none',
        },
        // Ideally we'd just set `justify-content: end`, but in that case
        // overflow at the top won't show a scrollbar. That behavior won't get fixed:
        // https://bugs.chromium.org/p/chromium/issues/detail?id=411624
        '&::before': {
            content: '""',
            display: 'block',
            marginTop: 'auto',
        },
    },
    followButton: {
        alignSelf: 'flex-end',
    },
    followButtonHide: {
        visibility: 'hidden',
    },
}));
export function ConvoV2TranscriptList(props) {
    // To prevent re-rendering the entire list when only one transcript changes,
    // only trigger a re-render when the transcript count changes and let each
    // transcript item respond to its own updates.
    const allTranscripts = selectAllTranscripts(window.store.getState());
    const transcriptCount = useAppSelector(selectTotalTranscriptCount);
    const scrollableContainer = useRef(null);
    const { follow, showMoreButton } = useAutoScroll(scrollableContainer, selectTranscripts);
    const [animate, setAnimate] = useState(false);
    const classes = useStyles();
    useEffect(() => {
        setTimeout(() => {
            setAnimate(true);
        }, 1000);
    }, []);
    if (!allTranscripts.length) {
        return null;
    }
    return (React.createElement(Box, { className: classNames(classes.root, props.className) },
        React.createElement(Box, { className: classes.list, ref: scrollableContainer }, allTranscripts.map((transcript) => (React.createElement(ConvoV2TranscriptItem, { key: transcript.id, transcriptId: transcript.id, animate: animate })))),
        React.createElement(ConvoV2FollowTranscriptButton, { className: classNames(classes.followButton, { [classes.followButtonHide]: !showMoreButton }), onClick: follow })));
}
