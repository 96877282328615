import KeyboardOutlinedIcon from '@mui/icons-material/KeyboardOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SquareIcon from '@mui/icons-material/Square';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import { useV1Socket } from '../../hooks/useV1Socket';
import { selectFirebaseUser } from '../../selectors/auth';
import { selectStatus } from '../../selectors/legacy-conversation';
import { selectAvaId } from '../../selectors/userProfile';
import { updateKeyboardInputShown } from '../../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { getDefaultRoomId, sendResetRoomIdMessage, sendRoomStateUpdateMessage } from '../../utils/ws-v1';
import AudioRecorder from '../AudioRecorder/AudioRecorder';
import { ConvoV2CaptionQualityButton } from './ConvoV2CaptionQualityButton';
import { ConvoV2ControlBarButton } from './ConvoV2ControlBarButton';
const useStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingY: 0,
        height: 0,
        overflow: 'hidden',
        transition: 'transform 0.3s ease-in-out',
        transform: 'translateY(100%)',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'white',
        minWidth: '200px',
        gap: 10,
        padding: 15,
        borderRadius: '56px',
        boxShadow: '2px 2px 10px 0px rgba(0,0,0,0.1)',
        boxSizing: 'border-box',
    },
    rootOpen: {
        paddingY: 2,
        height: 120,
        overflow: 'hidden',
        transform: 'translateY(0%)',
    },
    stopButton: {
        border: `1px solid ${theme.palette.error.main}`,
    },
    stopSquare: {
        color: theme.palette.error.main,
    },
}));
export function ConvoV2ControlBar({ className, open }) {
    const status = useAppSelector(selectStatus);
    const [ws] = useV1Socket();
    const avaId = useAppSelector(selectAvaId);
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const dispatch = useAppDispatch();
    const classes = useStyles();
    return (React.createElement(Box, { className: classNames(classes.root, className, { [classes.rootOpen]: open }) },
        React.createElement(Box, { className: classes.content },
            React.createElement(ConvoV2ControlBarButton, null,
                React.createElement(Box, { sx: { marginTop: '10px', marginLeft: '1px' } },
                    React.createElement(AudioRecorder, { hideTextBelow: true }))),
            React.createElement(ConvoV2ControlBarButton, { onClick: () => {
                    dispatch(updateKeyboardInputShown(true));
                } },
                React.createElement(KeyboardOutlinedIcon, null)),
            React.createElement(ConvoV2CaptionQualityButton, null),
            React.createElement(ConvoV2ControlBarButton, null,
                React.createElement(SettingsOutlinedIcon, null)),
            React.createElement(ConvoV2ControlBarButton, { className: classes.stopButton, onClick: () => {
                    // TODO: This is copied from ConversationPage. Abstract out.
                    if (status.host && status.host.avaId === avaId) {
                        if (ws)
                            sendRoomStateUpdateMessage(ws, { value: 'ended' });
                    }
                    if (ws)
                        sendResetRoomIdMessage(ws, getDefaultRoomId(firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.uid));
                } },
                React.createElement(SquareIcon, { className: classes.stopSquare })))));
}
