import { Popover } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, { useRef, useState } from 'react';
import { selectUniqueParticipants } from '../../selectors/legacy-conversation';
import { useAppSelector } from '../../store/store';
import { ConvoV2ParticipantAvatar } from './ConvoV2ParticipantAvatar';
import { ConvoV2ParticipantsCard } from './ConvoV2ParticipantsCard';
export function ConvoV2ParticipantListBadge() {
    const participants = useAppSelector(selectUniqueParticipants);
    const [participantCardOpen, setParticipantCardOpen] = useState(false);
    const toggleButtonRef = useRef(null);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: () => {
                setParticipantCardOpen(true);
            }, ref: toggleButtonRef, sx: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                height: 48,
                backgroundColor: 'white',
                border: '1px solid rgba(0, 0, 0, 0.2)',
                borderRadius: '48px',
                paddingLeft: 1,
                paddingRight: 2,
            } },
            participants.slice(0, 3).map((participant, index) => (React.createElement(Box, { key: participant.avaId, sx: {
                    display: 'flex',
                    flexDirection: 'row',
                    marginRight: '-8px',
                    zIndex: 100 - index,
                } },
                React.createElement(ConvoV2ParticipantAvatar, { speaker: participant, size: 30 })))),
            React.createElement(Typography, { sx: {
                    marginLeft: 2,
                } }, participants.length)),
        toggleButtonRef.current && (React.createElement(Popover, { open: participantCardOpen, anchorEl: toggleButtonRef.current, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, transformOrigin: { vertical: 'top', horizontal: 'right' }, PaperProps: {
                style: {
                    marginTop: 8,
                    minWidth: 340,
                    borderRadius: 20,
                    boxShadow: '0px 4px 8px 0px rgba(112, 144, 176, 0.20)',
                },
            } },
            React.createElement(ConvoV2ParticipantsCard, { onClose: () => setParticipantCardOpen(false) })))));
}
