import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getPhraseBoldData } from '../utils/i18n';
const useStyles = makeStyles({
    bold: {
        fontWeight: 600,
    },
});
const PhraseMarkdownText = ({ className, phraseKey }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const phrase = t(phraseKey);
    const containsBold = phrase.includes('**');
    if (containsBold) {
        return (React.createElement(React.Fragment, null, getPhraseBoldData(phrase, '**').map((phraseData, index) => (React.createElement("span", { key: index, className: classnames(className, {
                [classes.bold]: phraseData.isBold,
            }) }, phraseData.text)))));
    }
    return React.createElement("span", { className: classnames(className) }, phrase);
};
export default PhraseMarkdownText;
