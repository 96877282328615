import { Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import AvaLogoSphere from '../../../assets/images/ava_logo_sphere.png';
import SettingsImage from '../../../assets/images/settings.svg';
import StartButtonImage from '../../../assets/images/start-button.svg';
import SupportImage from '../../../assets/images/support.svg';
import { show as showIntercom } from '../../utils/intercom';
import { CONVOV2_HOME_PAGE_ROUTE } from './constants';
import { ConvoV2LeftSidebarItem } from './ConvoV2LeftSidebarItem';
import { ConvoV2NewSessionButton } from './ConvoV2NewSessionButton';
import { ConvoV2TranscriptIcon } from './ConvoV2TranscriptIcon';
const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.ava.white,
        display: 'flex',
        width: 80,
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px 0',
    },
    rootExanded: {
        width: 272,
        alignItems: 'start',
        padding: '20px 28px',
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20,
        gap: 8,
    },
    logo: {
        height: 40,
    },
    logoText: {
        color: theme.palette.ava.blue2,
        fontSize: 48,
        fontWeight: 600,
    },
    item: {
        width: '100%',
        '&:visited': {
            color: 'inherit',
        },
    },
    topItems: {
        width: '100%',
        flexGrow: 2,
        display: 'flex',
        gap: 4,
        flexDirection: 'column',
        alignItems: 'center',
    },
    topItemsExpanded: {
        alignItems: 'start',
    },
    startButton: {
        marginBottom: 16,
    },
    newSessionButton: {
        alignSelf: 'center',
        marginBottom: 16,
    },
}));
export function ConvoV2LeftSidebar({ className }) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const isHomePageActive = pathname === CONVOV2_HOME_PAGE_ROUTE;
    const expanded = isHomePageActive;
    const transcriptIcon = (React.createElement(ConvoV2TranscriptIcon, { backgroundColor: isHomePageActive ? theme.palette.ava.blue2 : theme.palette.ava.grey5 }));
    return (React.createElement(Box, { className: classNames(classes.root, className, { [classes.rootExanded]: expanded }) },
        React.createElement(Box, { className: classes.logoContainer },
            React.createElement("img", { className: classes.logo, src: AvaLogoSphere, alt: "Ava logo" }),
            expanded && React.createElement(Typography, { className: classes.logoText }, "ava")),
        React.createElement(Box, { className: classNames(classes.topItems, { [classes.topItemsExpanded]: expanded }) },
            expanded && React.createElement(ConvoV2NewSessionButton, { className: classes.newSessionButton }),
            !expanded && (React.createElement(Box, { className: classes.startButton },
                React.createElement("img", { src: StartButtonImage, alt: t('sidebar.buttons.startCaptions') }))),
            React.createElement(Link, { className: classes.item, to: CONVOV2_HOME_PAGE_ROUTE },
                React.createElement(ConvoV2LeftSidebarItem, { iconComponent: transcriptIcon, text: t('sidebar.conversations'), expanded: expanded, active: isHomePageActive })),
            React.createElement(ConvoV2LeftSidebarItem, { iconPath: SettingsImage, text: t('sidebar.accountSettings'), expanded: expanded })),
        React.createElement(ConvoV2LeftSidebarItem, { iconPath: SupportImage, text: t('sidebar.contactSupport'), expanded: expanded, onClick: () => {
                showIntercom();
            } })));
}
