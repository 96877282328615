export const sendWsMessage = (ws, message) => {
    if (ws) {
        ws.send(JSON.stringify(message));
    }
};
export const sendConnectionRequestMessage = (ws, fromSource, to, role, options = {}) => {
    const id = Date.now().toString();
    const message = Object.assign({ type: 'connection-request', fromSource,
        id,
        to,
        role }, options);
    sendWsMessage(ws, message);
    return id;
};
export const sendWebRTCTrackMetadataMessage = (ws, metadata) => {
    return sendWsMessage(ws, Object.assign({ type: 'webrtc-track-metadata' }, metadata));
};
export const sendAssignVoicingSpearkerMyself = (ws, speakerId) => {
    return sendWsMessage(ws, {
        type: 'assign-voicing-speaker-myself',
        speakerId,
    });
};
export const sendAssignVoicingSpearkerName = (ws, speakerId, speakerName) => {
    return sendWsMessage(ws, {
        type: 'assign-voicing-speaker-name',
        speakerId,
        speakerName,
    });
};
export const sendResetRoomIdMessage = (ws, roomId) => {
    return sendWsMessage(ws, {
        type: 'connection-params-update',
        roomId,
    });
};
export const sendSaveTranscriptUpdateMessage = (ws, saveTranscript) => {
    return sendWsMessage(ws, {
        type: 'connection-params-update',
        saveTranscript,
    });
};
export const sendConversationModeUpdateMessage = (ws, conversationMode) => {
    return sendWsMessage(ws, {
        type: 'connection-params-update',
        conversationMode,
    });
};
export const sendRoomStateUpdateMessage = (ws, state) => {
    return sendWsMessage(ws, {
        type: 'connection-params-update',
        state,
    });
};
export const sendUsePaidAsrUpdateMessage = (ws, usePaidASR) => sendWsMessage(ws, {
    type: 'connection-params-update',
    usePaidASR,
});
export const sendAccountUpdateMessage = (ws) => sendWsMessage(ws, {
    type: 'account-update',
});
export const getDefaultRoomId = (id) => `${id.slice(0, 8)}_00000000-0000-0000-0000-000000000000`;
export const isDefaultRoomId = (roomId) => roomId.includes('_00000000-0000-0000-0000-000000000000');
export const isConversationEnded = (status, avaId) => {
    const host = (status.id || '').split('_')[0];
    // eslint-disable-next-line
    for (let participant of status.participants || []) {
        // Host is present in the conversation.
        if (status.id && host === participant.avaId) {
            return false;
        }
    }
    // We are the host.
    if (status.id && host === avaId) {
        return false;
    }
    return true;
};
export const sendUserQueryMessage = (ws, avaNameStartsWith) => sendWsMessage(ws, {
    type: 'user-query',
    avaNameStartsWith,
});
export const sendEmailQueryMessage = (ws, emailEqualTo) => sendWsMessage(ws, {
    type: 'user-query',
    emailEqualTo,
});
export const updateDownAudio = (ws, speaker) => {
    if (speaker.audioLink) {
        return sendWsMessage(ws, {
            type: 'connection-params-update',
            downAudio: {
                forceSR: false,
                audioLink: true,
            },
        });
    }
    else {
        return sendWsMessage(ws, {
            type: 'connection-params-update',
            downAudio: {
                speakerId: speaker.avaId,
                trackId: speaker.trackId,
                forceSR: speaker.forceSR,
            },
        });
    }
};
export const sendMbackendMessage = (ws, data) => {
    return sendWsMessage(ws, {
        type: 'mbackend',
        content: JSON.stringify(data),
    });
};
export const sendBoostMessage = (ws, data) => {
    sendWsMessage(ws, Object.assign({ type: 'boost' }, data));
};
export const sendAudioParams = (ws, audioParams) => sendWsMessage(ws, {
    type: 'connection-params-update',
    audio: audioParams,
});
export const sendUpdateTranscriptOwnerMessage = (ws, message) => sendWsMessage(ws, Object.assign({ type: 'update-transcript-owner' }, message));
export const sendUpdateTitleMessage = (ws, message) => sendWsMessage(ws, Object.assign({ type: 'update-title' }, message));
export const sendForceMute = (ws, avaId) => sendWsMessage(ws, {
    type: 'force-mute',
    avaId,
});
export const sendTypeOnly = (ws, type) => sendWsMessage(ws, {
    type,
});
export const sendPing = (ws) => sendWsMessage(ws, {
    type: 'ping',
    id: Date.now().toString(),
});
export const respondPingMessage = (ws, pongMessage) => sendWsMessage(ws, {
    type: 'PONG',
    serverTimeMs: pongMessage.serverTimeMs,
    clientTimeMs: Date.now(),
});
export const sendAccessBoostWords = (ws, action, words) => {
    sendWsMessage(ws, {
        type: 'access-boost-words',
        action,
        words,
    });
};
