import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import Collapse from '@mui/material/Collapse/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon/ListItemIcon';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import { makeStyles } from '@mui/styles';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Toggle from 'react-toggled';
import translationLangs from '../../../assets/translation_langs.json';
import { switchLang } from '../../actions/conversation';
import { useV1Socket } from '../../hooks/useV1Socket';
import { selectSelectedMicrophone } from '../../selectors/audioV2';
import { selectAudioV2Status } from '../../selectors/audioV2';
import { selectAutoTranslateActive } from '../../selectors/avaTranslate';
import { selectCanRecord } from '../../selectors/combined';
import { selectCurseFilter } from '../../selectors/conversation';
import { selectLang, selectSpeechLang } from '../../selectors/legacy-conversation';
import { maybeRestartRecording, prepareAudioParams } from '../../store/slices/audioV2';
import { RecordingStatus } from '../../store/slices/audioV2';
import { useAppSelector } from '../../store/store';
import { getLanguageNativeName } from '../../utils/AvaTranslate';
import { canShowFlagEmoji } from '../../utils/platform';
import { sendAudioParams } from '../../utils/ws-v1';
import { EmptyIcon, styles } from './SidebarHelpers';
const useStyles = makeStyles(styles);
const TranslationSelector = React.memo(function TranslationSelector() {
    const classes = useStyles();
    const { t } = useTranslation();
    const lang = useAppSelector(selectLang);
    const autoTranslateActive = useAppSelector(selectAutoTranslateActive);
    const curseFilter = useAppSelector(selectCurseFilter);
    const speechLang = useAppSelector(selectSpeechLang);
    const selectedMicrophone = useAppSelector(selectSelectedMicrophone);
    const recording = useAppSelector(selectAudioV2Status) === RecordingStatus.RECORDING;
    const canRecord = useAppSelector(selectCanRecord);
    const dispatch = useDispatch();
    const [ws] = useV1Socket();
    const languageChange = (languageCode) => {
        dispatch(switchLang(languageCode));
        dispatch(maybeRestartRecording());
        // if the conversation is muted, then audio params aren't sent
        // then we can send translation
        // check for !recording || !canRecord
        if (ws && (!recording || !canRecord)) {
            const audioParams = prepareAudioParams(curseFilter, languageCode, speechLang, selectedMicrophone);
            sendAudioParams(ws, audioParams);
        }
    };
    const currentLangObject = translationLangs.find((lang_) => lang_.languageCode === lang);
    const currentFlagCode = currentLangObject && currentLangObject.flagCode;
    if (autoTranslateActive) {
        return null;
    }
    return (React.createElement(Toggle, null, ({ on, getTogglerProps, toggle }) => (React.createElement("div", { style: {
            display: 'flex',
            flexDirection: 'column',
        } },
        React.createElement("div", null,
            React.createElement(ListItem, Object.assign({ button: true }, getTogglerProps()),
                React.createElement(ListItemIcon, null,
                    React.createElement(LanguageOutlinedIcon, null)),
                React.createElement(ListItemText, { classes: { root: classes.listItemText } },
                    t('sidebar.switchLang'),
                    React.createElement("br", null),
                    React.createElement("b", { style: { fontWeight: 600 } },
                        currentFlagCode && (React.createElement(ReactCountryFlag, { style: { marginRight: 5 }, svg: !canShowFlagEmoji(), countryCode: currentFlagCode })),
                        lang === '~'
                            ? `\uD83C\uDF0D \u00A0${t('sidebar.chooseLanguage')}`
                            : getLanguageNativeName(lang))),
                React.createElement(ListItemIcon, { classes: { root: classes.expandIcon } }, on ? React.createElement(ExpandLessOutlinedIcon, null) : React.createElement(ExpandMoreOutlinedIcon, null))),
            React.createElement(Collapse, { in: on },
                React.createElement(List, null,
                    lang !== '~' && (React.createElement(ListItem, { key: "~", button: true },
                        React.createElement(EmptyIcon, null),
                        React.createElement(ListItemText, { classes: { root: classes.listItemText }, onClick: () => {
                                toggle();
                                languageChange('~');
                            } },
                            React.createElement("span", { role: "img", "aria-label": "cancel" }, "\u274C"),
                            ' ',
                            t('sidebar.stopTranslation')))),
                    translationLangs.map(({ languageCode, flagCode }) => (React.createElement(ListItem, { key: languageCode, button: true },
                        React.createElement(EmptyIcon, null),
                        React.createElement(ListItemText, { classes: { root: classes.listItemText }, onClick: () => {
                                toggle();
                                languageChange(languageCode);
                            } },
                            React.createElement(ReactCountryFlag, { style: { marginRight: 5 }, svg: !canShowFlagEmoji(), countryCode: flagCode }),
                            getLanguageNativeName(languageCode))))))))))));
});
export default TranslationSelector;
