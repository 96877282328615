import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WebsocketReconnectingBanner from '../../../components/Network/WebsocketReconnectingBanner';
import { useNotifications } from '../../../hooks/useNotifications';
import ConversationEndModals from '../../../modules/conversation/ConversationEndModals';
import ScribeTranscriptListContainer from '../../../modules/scribe/ScribeTranscriptListContainer';
import ScribeVolumeController from '../../../modules/scribe/ScribeVolumeController';
import SoloDiaSpeakerNameModal, { SoloDiaNameModalProvider } from '../../../modules/scribe/SoloDiaSpeakerNameModal';
import { selectConversationMuted, selectShouldShowSoloDiaNotification } from '../../../selectors/combined';
import { selectConversationEnded, selectIsInConversation, selectJoiningStatus } from '../../../selectors/conversation';
import { selectParticipants, selectStatusScribe } from '../../../selectors/legacy-conversation';
import { selectIsScribing } from '../../../selectors/scribe';
import { selectFullScreen, selectInsertSpeakerNameMode } from '../../../selectors/ui';
import { setJoiningStatus } from '../../../store/slices/conversation';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import useStyles from './ConversationView.styles';
const ConversationView = ({ conversationContainerHovered, showZoomBroadcastingWarning }) => {
    const classes = useStyles({ conversationContainerHovered });
    const { t } = useTranslation();
    const fullScreen = useAppSelector(selectFullScreen);
    const insertSpeakerNameMode = useAppSelector(selectInsertSpeakerNameMode);
    const scribing = useAppSelector(selectIsScribing);
    const joiningStatus = useAppSelector(selectJoiningStatus);
    const muted = useAppSelector(selectConversationMuted);
    const isInConversation = useAppSelector(selectIsInConversation);
    const isConversationEnded = useAppSelector(selectConversationEnded);
    const statusScribe = useAppSelector(selectStatusScribe);
    const statusParticipants = useAppSelector(selectParticipants);
    const shouldShowSoloDiaNotification = useAppSelector(selectShouldShowSoloDiaNotification);
    const dispatch = useAppDispatch();
    const { notify } = useNotifications();
    const [notificationShowed, setNotificationShowed] = useState(false);
    useEffect(() => {
        if (joiningStatus === 'accepted') {
            notify(t('conversation.inviteCard.joinedAvaRoom'));
            dispatch(setJoiningStatus('none'));
        }
    }, [joiningStatus]);
    useEffect(() => {
        if (shouldShowSoloDiaNotification && !notificationShowed) {
            notify('', {
                variant: 'soloDia',
                disableWindowBlurListener: true,
                persist: true,
                preventDuplicate: true,
            });
            setNotificationShowed(true);
        }
    }, [shouldShowSoloDiaNotification]);
    return (React.createElement("div", { style: { display: 'flex' } },
        React.createElement(WebsocketReconnectingBanner, { className: classes.reconnectBanner }),
        insertSpeakerNameMode && (React.createElement("div", { style: { backgroundColor: 'darksalmon', width: '100%', height: 30, textAlign: 'center' } }, "Insert Speaker Name mode")),
        scribing && statusScribe && statusScribe.status === 'leaving' && (React.createElement("div", { style: {
                backgroundColor: 'darksalmon',
                width: '100%',
                height: 30,
                textAlign: 'center',
                padding: 5,
                position: 'relative',
                zIndex: 102,
            } },
            React.createElement("span", null, t('scribeEndRequested')))),
        isInConversation && (React.createElement(SoloDiaNameModalProvider, null,
            React.createElement(ScribeTranscriptListContainer, null),
            React.createElement(SoloDiaSpeakerNameModal, null))),
        window.isElectron && !muted && isInConversation && !scribing && (React.createElement(ScribeVolumeController, { className: classes.volumeController })),
        statusParticipants && !scribing && !fullScreen && isInConversation && (React.createElement(React.Fragment, null,
            React.createElement(ConversationEndModals, null),
            showZoomBroadcastingWarning && (React.createElement(Dialog, { className: classes.zoomBroadcastWarningDialog, open: showZoomBroadcastingWarning, keepMounted: true, onClose: () => ({}) },
                React.createElement(DialogContent, null,
                    React.createElement("div", { className: classes.zoomBroadcastWarningIconWrap },
                        React.createElement(Typography, { className: classes.zoomBroadcastWarningIconText }, "CC")),
                    React.createElement(Typography, { variant: "h4", className: classes.zoomBroadcastWarningTitle, align: "center", gutterBottom: true }, t('conversation.zoomExportWarning.title')),
                    React.createElement(Typography, { variant: "body1", align: "center", gutterBottom: true }, t('conversation.zoomExportWarning.body1')),
                    React.createElement(Typography, { variant: "body1", align: "center", gutterBottom: true }, t('conversation.zoomExportWarning.body2'))),
                React.createElement(DialogActions, { className: classes.zoomBroadcastWarningDialogActions },
                    React.createElement(Button, { className: classes.zoomBroadcastWarningButton }, t('conversation.zoomExportWarning.confirm')),
                    React.createElement(Button, { className: classes.zoomBroadcastWarningCancel, variant: "text" }, t('conversation.zoomExportWarning.cancel')))))))));
};
export default ConversationView;
