import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ExpandIcon from '../../../../assets/icons/expand.svg';
import KeyboardArrowDown from '../../../../assets/icons/keyboard-arrow-down.svg';
import ConnectToMeetingsCard from '../../../components/Cards/ConnectToMeetingsCard/ConnectToMeetingsCard';
import InviteCard from '../../../components/Cards/InviteCard/InviteCard';
import AddParticipantButton from '../../../components/CCMode/AddParticipantButton';
import ConnectToMeetingsButton from '../../../components/CCMode/ConnectToMeetingsButton';
import LoadingText from '../../../components/LoadingText';
import WebsocketReconnectingBanner from '../../../components/Network/WebsocketReconnectingBanner';
import ParticipantList from '../../../components/ParticipantList';
import { useNotifications } from '../../../hooks/useNotifications';
import { useV1Socket } from '../../../hooks/useV1Socket';
import AudioRecorder from '../../../modules/AudioRecorder/AudioRecorder';
import RoomEndingWarningSnackbar from '../../../modules/conversation/RoomEndingWarningSnackbar';
import ScribeKeypressHandlerContainer from '../../../modules/scribe/ScribeKeypressHandlerContainer';
import ScribeTranscriptListContainer from '../../../modules/scribe/ScribeTranscriptListContainer';
import ScribeVolumeController from '../../../modules/scribe/ScribeVolumeController';
import SoloDiaSpeakerNameModal, { SoloDiaNameModalProvider } from '../../../modules/scribe/SoloDiaSpeakerNameModal';
import { selectShowBubbleAboveCCMode } from '../../../selectors/ccMode';
import { selectConversationMuted, selectShouldShowSoloDiaNotification, selectShowKeyboardInput, } from '../../../selectors/combined';
import { selectIsInConversation, selectJoiningStatus } from '../../../selectors/conversation';
import { selectIsDefaultRoomId, selectStatusRoomId } from '../../../selectors/legacy-conversation';
import { selectRecallAIStatus } from '../../../selectors/recallAI';
import { selectIsScribing } from '../../../selectors/scribe';
import { selectTtsError } from '../../../selectors/textToSpeech';
import { selectIsConnectToMeetingOpen } from '../../../selectors/ui';
import { selectIsInviteCardOpen, selectKeyboardInputShown } from '../../../selectors/ui';
import { selectDisplayShortcuts, selectFontSize } from '../../../selectors/ui';
import { selectFeature, selectHearingProfile, selectIsUserTemporary } from '../../../selectors/userProfile';
import { addNotification, clearNotifications, setShowBubbleAboveCCModeDebounced } from '../../../store/slices/ccMode';
import { setJoiningStatus } from '../../../store/slices/conversation';
import { initiateConversationEndSequence, setConversationEndInitiatedByCurrentUser, } from '../../../store/slices/conversationEnd';
import { setIsInviteCardOpen, updateKeyboardInputShown } from '../../../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { isWindows } from '../../../utils';
import KeyboardInput from '../KeyboardInput/KeyboardInput';
import NotificationContainer from '../NotificationContainer/NotificationContainer';
import useStyles from './ConversationView.styles';
const CCConversationView = () => {
    const fontSize = useAppSelector(selectFontSize);
    const showBubbleAboveCCMode = useAppSelector(selectShowBubbleAboveCCMode);
    const classes = useStyles({ showBubbleAboveCCMode, fontSize });
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isInviteCardOpen = useAppSelector(selectIsInviteCardOpen);
    const displayShortcuts = useAppSelector(selectDisplayShortcuts);
    const scribing = useAppSelector(selectIsScribing);
    const joiningStatus = useAppSelector(selectJoiningStatus);
    const keyboardInputShown = useAppSelector(selectKeyboardInputShown);
    const muted = useAppSelector(selectConversationMuted);
    const isInConversation = useAppSelector(selectIsInConversation);
    const isConnectToMeetingsOpen = useAppSelector(selectIsConnectToMeetingOpen);
    const recalAIStatus = useAppSelector(selectRecallAIStatus);
    const tempUser = useAppSelector(selectIsUserTemporary);
    const showKeyboardButton = useAppSelector(selectShowKeyboardInput);
    const hearingProfile = useAppSelector(selectHearingProfile);
    const ttsError = useAppSelector(selectTtsError);
    const [_, websocketStatus] = useV1Socket();
    const roomId = useAppSelector(selectStatusRoomId);
    const defaultRoomId = useAppSelector(selectIsDefaultRoomId);
    const shouldShowSoloDiaNotification = useAppSelector(selectShouldShowSoloDiaNotification);
    const navigate = useNavigate();
    const { notify } = useNotifications();
    const featuresScribe = useAppSelector(selectFeature('scribe'));
    const featuresRecallAI = useAppSelector(selectFeature('recall-ai'));
    const keyboardInputComponent = useRef(null);
    const [notificationShowed, setNotificationShowed] = useState(false);
    const showAudioControlsAndKeyboard = showKeyboardButton || (!scribing && !muted && isInConversation);
    useEffect(() => {
        return () => {
            dispatch(clearNotifications());
            dispatch(setShowBubbleAboveCCModeDebounced(false));
            dispatch(setIsInviteCardOpen(false));
            dispatch(setJoiningStatus('none'));
        };
    }, []);
    useEffect(() => {
        if (hearingProfile === 0) {
            setTimeout(() => {
                if (window.electronIPC) {
                    window.electronIPC.sendCCMode('keyboard', true);
                }
                dispatch(updateKeyboardInputShown(true));
            }, 400);
        }
    }, [hearingProfile]);
    useEffect(() => {
        if (joiningStatus === 'accepted') {
            dispatch(addNotification({
                text: t('conversation.inviteCard.joinedAvaRoom'),
                timestamp: Date.now(),
                type: 'success',
            }));
        }
    }, [joiningStatus]);
    useEffect(() => {
        if (ttsError.length) {
            dispatch(addNotification({
                text: t('ccMode.keyboard.errors.unableToHear'),
                timestamp: Date.now(),
                type: 'default',
            }));
        }
    }, [ttsError]);
    useEffect(() => {
        if (shouldShowSoloDiaNotification && !notificationShowed) {
            notify('', {
                variant: 'soloDia',
                disableWindowBlurListener: true,
                persist: true,
                preventDuplicate: true,
            });
            setNotificationShowed(true);
        }
    }, [shouldShowSoloDiaNotification]);
    const onEndConversation = () => {
        dispatch(setConversationEndInitiatedByCurrentUser(true));
        dispatch(initiateConversationEndSequence());
    };
    return (React.createElement(SoloDiaNameModalProvider, null,
        React.createElement("div", { className: classes.root },
            React.createElement("section", { className: classnames(classes.topContainer, {
                    [classes.visible]: showBubbleAboveCCMode,
                }) },
                React.createElement("div", { className: classes.topContainerLeft },
                    React.createElement("div", { className: classes.participants },
                        roomId && !defaultRoomId && (React.createElement(ParticipantList, { scribe: featuresScribe && scribing, displayShortcuts: displayShortcuts })),
                        React.createElement(AddParticipantButton, { isActive: isInviteCardOpen }),
                        isConnectToMeetingsOpen && featuresRecallAI ? (React.createElement(ConnectToMeetingsCard, null)) : featuresRecallAI ? (React.createElement(ConnectToMeetingsButton, { isActive: isConnectToMeetingsOpen })) : (React.createElement("div", { style: { display: 'none' } }))),
                    React.createElement(SoloDiaSpeakerNameModal, null)),
                React.createElement("div", { className: classes.conversationButtons },
                    React.createElement(Tooltip, { title: t('ccMode.buttons.fullScreen'), placement: "bottom", disableInteractive: true },
                        React.createElement("div", null,
                            React.createElement("button", { className: classes.conversationButton, onClick: () => navigate('/web/transcript') },
                                React.createElement("img", { src: ExpandIcon, alt: "" })))),
                    React.createElement(Tooltip, { title: t('ccMode.buttons.closeRoom'), placement: "bottom", disableInteractive: true },
                        React.createElement("div", null,
                            React.createElement("button", { className: classnames(classes.conversationButton, classes.closeButton), onClick: onEndConversation },
                                React.createElement(CloseIcon, { className: classes.closeIcon })))))),
            React.createElement("section", { className: classnames(classes.conversationContainer, {
                    [classes.conversationContainerKeyboardOpen]: keyboardInputShown,
                    [classes.readOnlyConversation]: !showAudioControlsAndKeyboard,
                }), onMouseEnter: () => dispatch(setShowBubbleAboveCCModeDebounced(true)) },
                React.createElement("div", { className: classes.conversationDraggableContainer },
                    React.createElement(WebsocketReconnectingBanner, null),
                    React.createElement(NotificationContainer, null),
                    React.createElement(RoomEndingWarningSnackbar, { variant: 'cc' }),
                    React.createElement("span", null, websocketStatus === 'online' && (!muted || (recalAIStatus === 'CAPTIONING' && !tempUser)) && (React.createElement(ScribeKeypressHandlerContainer, null))),
                    !isInConversation || joiningStatus === 'requested' ? (React.createElement("div", { className: classes.loadingTextContainer },
                        React.createElement(LoadingText, { className: classes.loadingText }, joiningStatus === 'requested'
                            ? t('ccMode.conversation.joiningAvaRoom')
                            : t('ccMode.conversation.startingAvaRoom')))) : (React.createElement(React.Fragment, null,
                        ((isWindows && !window.__TAURI__) || showAudioControlsAndKeyboard) && (React.createElement("div", { className: classes.controlsContainer }, showAudioControlsAndKeyboard && (React.createElement(React.Fragment, null,
                            React.createElement(ScribeVolumeController, { className: classes.volumeController, electronCaptionMode: true }),
                            React.createElement(AudioRecorder, { hideButton: false, className: classes.audioRecorder, showExpandedMic: showBubbleAboveCCMode }),
                            React.createElement("div", { style: { height: 32 } }, !keyboardInputShown && (React.createElement(Tooltip, { classes: { tooltipPlacementTop: classes.tooltipPlacementTop }, title: t('ccMode.tooltip.keyboard'), placement: "top", disableInteractive: true },
                                React.createElement("button", { className: classes.openKeyboardInputButton, onClick: () => {
                                        var _a;
                                        (_a = window.electronIPC) === null || _a === void 0 ? void 0 : _a.sendCCMode('keyboard', true);
                                        dispatch(updateKeyboardInputShown(true));
                                    } },
                                    React.createElement("img", { src: KeyboardArrowDown, alt: t('ccMode.keyboard.keyboardArrowDownButton') }))))))))),
                        React.createElement("div", { className: classes.transcriptsContainer },
                            isInviteCardOpen && (React.createElement("div", { className: classes.inviteCardContainer },
                                React.createElement(InviteCard, null))),
                            React.createElement(ScribeTranscriptListContainer, null)))))),
            React.createElement("section", { ref: keyboardInputComponent, className: classnames(classes.bottomContainer, {
                    [classes.keyboardInputOpen]: keyboardInputShown,
                }), onMouseEnter: () => dispatch(setShowBubbleAboveCCModeDebounced(true)) }, keyboardInputShown && (React.createElement(KeyboardInput, { onCloseKeyboard: () => {
                    var _a;
                    dispatch(updateKeyboardInputShown(false));
                    (_a = window.electronIPC) === null || _a === void 0 ? void 0 : _a.sendCCMode('keyboard', false);
                } }))))));
};
export default CCConversationView;
