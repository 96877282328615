import { createSelector } from 'reselect';
import { binarySearchArray, compareIntegerStrings } from '../utils/binarySearch';
import { isDefaultRoomId } from '../utils/ws-v1';
import { selectAvaId } from './userProfile';
const selectScribeConversation = (state) => state.scribeConversation;
// DO NOT USE. Gets updated 10 times a second sometimes. Use sub-selectors.
export const selectStatus = createSelector([selectScribeConversation], (scribeConversation) => scribeConversation.status);
const selectStatusPermissions = createSelector([selectStatus], (status) => status === null || status === void 0 ? void 0 : status.permissions);
export const selectPermissionsTranslation = createSelector([selectStatusPermissions], (permissions) => { var _a; return (_a = permissions === null || permissions === void 0 ? void 0 : permissions.translation) === null || _a === void 0 ? void 0 : _a.value; });
export const selectStatusScribe = createSelector([selectStatus], (status) => status === null || status === void 0 ? void 0 : status.scribe);
export const selectHasScribe = createSelector([selectStatus], (status) => { var _a; return ((_a = status === null || status === void 0 ? void 0 : status.scribe) === null || _a === void 0 ? void 0 : _a.status) === 'ongoing'; });
export const selectPendingScribe = createSelector([selectStatus], (status) => { var _a; return ((_a = status === null || status === void 0 ? void 0 : status.scribe) === null || _a === void 0 ? void 0 : _a.status) === 'pending'; });
export const selectStatusAudioStreams = createSelector([selectStatus], (status) => status === null || status === void 0 ? void 0 : status.audioStreams);
export const selectStatusRoomId = createSelector([selectStatus], (status) => status === null || status === void 0 ? void 0 : status.id);
export const selectStatusHostAvaId = createSelector([selectStatus], (status) => { var _a; return (_a = status === null || status === void 0 ? void 0 : status.host) === null || _a === void 0 ? void 0 : _a.avaId; });
export const selectStatusHostAvaName = createSelector([selectStatus], (status) => { var _a; return (_a = status === null || status === void 0 ? void 0 : status.host) === null || _a === void 0 ? void 0 : _a.avaName; });
export const selectIsDefaultRoomId = createSelector([selectStatusRoomId], (roomId) => roomId && isDefaultRoomId(roomId));
export const selectStatusConversationMode = createSelector([selectStatus], (status) => status === null || status === void 0 ? void 0 : status.conversationMode);
export const selectStatusTitle = createSelector([selectStatus], (status) => status === null || status === void 0 ? void 0 : status.title);
export const selectStatusLinksRo = createSelector([selectStatus], (status) => { var _a; return (_a = status === null || status === void 0 ? void 0 : status.links) === null || _a === void 0 ? void 0 : _a.ro; });
export const selectStatusPast = createSelector([selectStatus], (status) => status === null || status === void 0 ? void 0 : status.past);
export const selectStatusReason = createSelector([selectStatus], (status) => status === null || status === void 0 ? void 0 : status.reason);
export const selectStatusStateValue = createSelector([selectStatus], (status) => { var _a; return (_a = status === null || status === void 0 ? void 0 : status.state) === null || _a === void 0 ? void 0 : _a.value; });
export const selectStatusRestrictionsConvoWarningCountdownTimerMs = createSelector([selectStatus], (status) => { var _a; return (_a = status === null || status === void 0 ? void 0 : status.restrictions) === null || _a === void 0 ? void 0 : _a.convoWarningCountdownTimerMs; });
export const selectTwilioCalls = createSelector([selectStatus], (status) => status === null || status === void 0 ? void 0 : status.twilioCalls);
export const selectZoomConference = createSelector([selectStatus], (status) => !!Object.values((status === null || status === void 0 ? void 0 : status.twilioCalls) || {}).find((call) => call.conferenceType === 'zoom'));
export const selectStatusBoost = createSelector([selectStatus], (status) => status === null || status === void 0 ? void 0 : status.boost);
// This is similar to status.participants, but:
// * It is a map, not an array
// * It can contain virtual participants (e.g. 'recall-ai')
// * It is enhanced with solo-dia information in the reducer
export const selectSpeakersMap = createSelector([selectScribeConversation], (scribeConversation) => scribeConversation.speakers);
// This is similar to speakers, but:
// * It is an array
// * It contains only real participants (no virtual participants)
// * It is exactly as returned by the backend (duplicate participants?)
export const selectParticipants = createSelector([selectScribeConversation], (scribeConversation) => { var _a; return ((_a = scribeConversation.status) === null || _a === void 0 ? void 0 : _a.participants) || []; });
export const selectParticipantsLength = createSelector([selectParticipants], (participants) => participants.length);
export const selectTranscriptOwnersLength = createSelector([selectStatus], (status) => { var _a; return ((_a = status === null || status === void 0 ? void 0 : status.transcriptOwners) === null || _a === void 0 ? void 0 : _a.length) || 0; });
export const selectParticipantsPresent = createSelector([selectParticipants], (participants) => !!participants);
// This is the best of both worlds for selectParticipants and selectSpeakers:
// * It is an array
// * It contains only real participants (no virtual participants)
// * It is enhanced with solo-dia information in the reducer
// * It does not contain duplicates
export const selectUniqueParticipants = createSelector([selectParticipants, selectSpeakersMap], (participants, speakers) => [...new Set(participants.map((p) => p.avaId))].map((avaId) => speakers[avaId]));
export const selectTranscripts = createSelector([selectScribeConversation], (scribeConversation) => scribeConversation.transcripts);
export const selectTranscriptsCurrent = createSelector([selectScribeConversation], (scribeConversation) => scribeConversation.transcriptsCurrent);
export const selectTranscriptsFinal = createSelector([selectScribeConversation], (scribeConversation) => scribeConversation.transcriptsFinal);
export const selectTotalTranscriptCount = createSelector([selectTranscriptsCurrent, selectTranscriptsFinal], (transcriptsCurrent, transcriptsFinal) => transcriptsCurrent.length + transcriptsFinal.length);
export const selectHaveTranscripts = createSelector([selectTotalTranscriptCount], (totalTranscriptCount) => totalTranscriptCount > 0);
export const selectLang = createSelector([selectScribeConversation], (scribeConversation) => scribeConversation.lang);
export const selectSpeechLang = createSelector([selectScribeConversation], (scribeConversation) => scribeConversation.speechLang);
export const selectAllTranscripts = createSelector([selectTranscripts, selectTranscriptsCurrent, selectTranscriptsFinal], (transcripts, transcriptsCurrent, transcriptsFinal) => transcriptsFinal.concat(transcriptsCurrent).map((transcriptId) => transcripts[transcriptId]));
export const selectTranscript = createSelector([selectTranscripts, (state, transcriptId) => transcriptId], (transcripts, transcriptId) => transcripts[transcriptId]);
// This selector is O(log N) in the worst case, doing a binary search over all transcripts
export const selectNeighborTranscriptAuthors = createSelector([
    selectTranscripts,
    selectTranscriptsCurrent,
    selectTranscriptsFinal,
    (state, transcriptId) => transcriptId,
], (transcripts, transcriptsCurrent, transcriptsFinal, transcriptId) => {
    var _a, _b;
    const allTranscriptIds = [...transcriptsFinal, ...transcriptsCurrent];
    const transcriptIndex = binarySearchArray(allTranscriptIds, transcriptId, compareIntegerStrings);
    if (transcriptIndex === -1) {
        return { previousTranscriptAuthor: undefined, nextTranscriptAuthor: undefined };
    }
    const previousTranscriptId = allTranscriptIds[transcriptIndex - 1];
    const nextTranscriptId = allTranscriptIds[transcriptIndex + 1];
    return {
        previousTranscriptAuthor: previousTranscriptId && ((_a = transcripts[previousTranscriptId]) === null || _a === void 0 ? void 0 : _a.author),
        nextTranscriptAuthor: nextTranscriptId && ((_b = transcripts[nextTranscriptId]) === null || _b === void 0 ? void 0 : _b.author),
    };
});
export const selectHighLightedById = createSelector([selectTranscripts, (state, transcriptId) => transcriptId], (transcripts, transcriptId) => { var _a; return Boolean((_a = transcripts === null || transcripts === void 0 ? void 0 : transcripts[transcriptId]) === null || _a === void 0 ? void 0 : _a.highlighted); });
export const selectAsrFinalById = createSelector([selectTranscripts, (state, transcriptId) => transcriptId], (transcripts, transcriptId) => { var _a; return Boolean((_a = transcripts === null || transcripts === void 0 ? void 0 : transcripts[transcriptId]) === null || _a === void 0 ? void 0 : _a['asr.final']); });
export const selectTranscriptAuthor = createSelector([selectTranscript], (transcript) => transcript.author);
export const selectFurthestObservedCursors = createSelector([selectScribeConversation], (scribeConversation) => scribeConversation.furthestObservedCursors);
export const selectIsHost = createSelector([selectStatus, selectAvaId], (status, avaId) => {
    // we check status.host.avaName for DevExp purposes
    // since when changing to other servers (eg PENTEST)
    // the first status.host.avaId is a default host value
    // that's unrelated to the actual host or any participants
    if (status && status.host && status.host.avaId && status.host.avaName && avaId) {
        return avaId === status.host.avaId;
    }
    else
        return false;
});
