import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        padding: '12px 28px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 7,
        borderRadius: 100,
        background: 'radial-gradient(120.92% 106.78% at 0% 0%, rgba(134, 190, 255, 0.98) 0%, rgba(134, 205, 255, 0.98) 0.01%, #50ACED 100%)',
        boxShadow: '4px 2px 12px 0px rgba(112, 144, 176, 0.25)',
        fontSize: 18,
        fontWeight: 600,
        color: theme.palette.ava.white,
        cursor: 'pointer',
        userSelect: 'none',
    },
    disabled: {
        opacity: '50%',
        cursor: 'default',
    },
}));
export function ConvoV2PrimaryButton({ children, disabled, className, onClick }) {
    const classes = useStyles();
    return (React.createElement(Box, { className: classNames(classes.root, className, { [classes.disabled]: disabled }), onClick: onClick }, children));
}
