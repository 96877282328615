var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { authInstance } from '../../firebase';
import { selectBoostManager } from '../../selectors/boost';
import { selectJoinConversationManager } from '../../selectors/conversation';
import { selectHaveTranscripts, selectIsHost, selectStatus } from '../../selectors/legacy-conversation';
import { selectRecallAIManager } from '../../selectors/recallAI';
import { selectElectronCaptionMode } from '../../selectors/ui';
import { selectAvaId, selectFeatures, selectSubscription } from '../../selectors/userProfile';
import * as Conversation from '../../utils/conversation';
import { navigateToTranscriptURLIfNeeded } from '../../utils/conversation';
import { clearSearchValue } from '../../utils/http';
import { JoinConversationManager } from '../../utils/joinConversation';
import * as segment from '../../utils/segment';
import { isUnlimitedPaidASR } from '../../utils/status';
import { getCurrentAvailableMics, resetAllMicsSelected, startRecording, stopRecording } from './audioV2';
import { setCCMode } from './ccMode';
import { initiateConversationEndSequence, resetConversationEndingState } from './conversationEnd';
import { setUrlToOpenAfterStartingConvo } from './quickSetup';
import { setConversationStatus, setRateConversationOpen } from './rateConversation';
import { addNewTranscript, setSelectedTranscriptId } from './savedTranscript';
import { setScribing } from './scribe';
import { scribeDashboardClear } from './scribeDashboard';
import { setConnectToMeetingsOpen, setLoading, toggleSideBar } from './uiState';
import { setV1Token } from './v1Session';
const branchSdk = require('branch-sdk');
const initialState = {
    editedWords: [],
    curseFilter: parseInt(localStorage.getItem('ava-curse-filter') || '2', 10),
    joiningStatus: 'none',
    selectedCaptions: 'free',
    isInConversation: false,
    conversationEnded: false,
    inTwilioCalls: false,
    createConversationWhenReady: false,
};
export const conversationSlice = createSlice({
    name: 'conversation',
    initialState,
    reducers: {
        addEditedWord(state, { payload }) {
            state.editedWords.push(payload);
        },
        setCurseFilter(state, { payload }) {
            window.localStorage.setItem('ava-curse-filter', payload.toString());
            state.curseFilter = payload;
        },
        setJoiningStatus(state, { payload }) {
            state.joiningStatus = payload;
        },
        setIsInConversation(state, { payload }) {
            state.isInConversation = payload;
        },
        setConversationEnded(state, { payload }) {
            state.conversationEnded = payload;
        },
        setSelectedCaptions(state, { payload }) {
            state.selectedCaptions = payload;
        },
        updateBroadcastToZoomToken(state, { payload }) {
            state.broadcastToZoomToken = payload;
        },
        setCreateConversationWhenReady(state, { payload }) {
            state.createConversationWhenReady = payload;
        },
        setJoinConversationError(state, { payload }) {
            state.joinConversationError = payload;
        },
        setWakeLockSentinel(state, { payload }) {
            state.conversationWakeLock = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase('ROOM_STATUS_UPDATE', (state, action) => {
            var _a;
            state.inTwilioCalls = action.status.twilioCalls && Object.keys(action.status.twilioCalls).length > 0;
            // backend will send usePaidASR in audioStreams if using premium captions
            if (action.status.audioStreams && ((_a = action.status.audioStreams[0]) === null || _a === void 0 ? void 0 : _a.usePaidASR)) {
                state.backendSelectedCaptions = 'premium';
            }
            else {
                state.backendSelectedCaptions = undefined;
            }
        });
        builder.addCase(createJoinConversationManager.fulfilled, (state, { payload }) => {
            if (payload)
                state.joinConversationManager = payload;
        });
    },
});
export const conversationReducer = conversationSlice.reducer;
export const { updateBroadcastToZoomToken, setSelectedCaptions, addEditedWord, setCurseFilter, setJoiningStatus, setCreateConversationWhenReady, } = conversationSlice.actions;
export const { setWakeLockSentinel, setIsInConversation, setConversationEnded, setJoinConversationError } = conversationSlice.actions;
export const updateConversationEnded = createAsyncThunk('conversation/updateConversationEnded', (conversationEnded, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const dispatch = thunkAPI.dispatch;
    dispatch(setConversationEnded(conversationEnded));
    if (conversationEnded) {
        clearSearchValue(window, 'endpoint');
        dispatch(setV1Token(undefined));
        dispatch(initiateConversationEndSequence());
    }
}));
export const resetSelectedCaptions = createAsyncThunk('conversation/resetSelectedCaptions', (_, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const state = thunkAPI.getState();
    const dispatch = thunkAPI.dispatch;
    const asrTime = Math.floor(((_a = state.userProfile) === null || _a === void 0 ? void 0 : _a.paidASRCreditTime) / 1000);
    const subscription = selectSubscription(state);
    const features = selectFeatures(state);
    const unlimitedPaidASR = isUnlimitedPaidASR(subscription, features);
    const canSelectPremium = unlimitedPaidASR || asrTime > 0;
    dispatch(setSelectedCaptions(canSelectPremium ? 'premium' : 'free'));
}));
export const updateIsInConversation = createAsyncThunk('conversation/updateIsInConversation', (isInConversation, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const state = thunkAPI.getState();
    const dispatch = thunkAPI.dispatch;
    if (isInConversation === state.conversation.isInConversation) {
        return thunkAPI.rejectWithValue(false);
    }
    if (window.electronIPC) {
        window.electronIPC.sendSetIsInConversation(isInConversation);
    }
    dispatch(setIsInConversation(isInConversation));
    if (isInConversation) {
        navigateToTranscriptURLIfNeeded();
        dispatch(setSelectedTranscriptId(''));
        const boostManager = selectBoostManager(state);
        branchSdk.closeJourney();
        dispatch(resetAllMicsSelected());
        yield dispatch(resetSelectedCaptions());
        yield dispatch(getCurrentAvailableMics());
        yield dispatch(startRecording());
        dispatch(resetConversationEndingState());
        if (window.navigator.mediaDevices) {
            window.navigator.mediaDevices.ondevicechange = () => dispatch(getCurrentAvailableMics());
        }
        if (window.navigator.wakeLock) {
            const wakeLockSentinel = yield window.navigator.wakeLock.request('screen');
            dispatch(setWakeLockSentinel(wakeLockSentinel));
        }
        const screenWidth = Math.max((document.documentElement && document.documentElement.clientWidth) || 0, window.innerWidth || 0);
        if (screenWidth > 768 && !window.isElectron) {
            dispatch(toggleSideBar(true));
        }
        boostManager === null || boostManager === void 0 ? void 0 : boostManager.handleLoadBoostWords();
        if (window.isElectron) {
            dispatch(setCCMode('conversation'));
        }
        dispatch(setLoading(false));
    }
    if (!isInConversation) {
        const isHost = selectIsHost(state);
        const recallAIManager = selectRecallAIManager(state);
        const joinConversationManager = selectJoinConversationManager(state);
        const status = selectStatus(state);
        const haveTranscripts = selectHaveTranscripts(state);
        const features = selectFeatures(state);
        const electronCaptionMode = selectElectronCaptionMode(state);
        const isSaveTranscript = features.saveTranscript;
        const isForbidConversationSaving = features['forbid-conversation-saving'];
        if (isSaveTranscript && !isForbidConversationSaving) {
            if (haveTranscripts) {
                dispatch(setSelectedTranscriptId(status.id));
                const newTranscript = Conversation.prepareTranscriptAfterConversation(state);
                dispatch(addNewTranscript(newTranscript));
            }
        }
        Conversation.trackHadConversationFromState(state);
        branchSdk.track('pageview');
        dispatch(setConversationStatus(status));
        if (haveTranscripts) {
            dispatch(setRateConversationOpen(true));
        }
        if (state.conversation.conversationWakeLock) {
            yield state.conversation.conversationWakeLock.release();
        }
        dispatch(setWakeLockSentinel(undefined));
        dispatch(stopRecording());
        dispatch(scribeDashboardClear());
        dispatch(setScribing(false));
        dispatch(resetConversationEndingState());
        recallAIManager === null || recallAIManager === void 0 ? void 0 : recallAIManager.reset(isHost);
        joinConversationManager === null || joinConversationManager === void 0 ? void 0 : joinConversationManager.reset();
        dispatch(setConnectToMeetingsOpen(false));
    }
    if (state.quickSetup.urlToOpenAfterStartingConvo && isInConversation) {
        const url = state.quickSetup.urlToOpenAfterStartingConvo;
        setTimeout(() => {
            if (window.desktopIntegration) {
                window.desktopIntegration.openExternalURL(url);
            }
        }, 500);
        dispatch(setUrlToOpenAfterStartingConvo(undefined));
    }
}));
export const createJoinConversationManager = createAsyncThunk('conversation/createJoinConversationManager', (_, thunkAPI) => {
    var _a, _b, _c;
    const dispatch = thunkAPI.dispatch;
    const state = thunkAPI.getState();
    const { v1Socket } = state.v1Session;
    const avaName = ((_a = state.userProfile.parse) === null || _a === void 0 ? void 0 : _a.avaName) || '';
    const firebaseUser = ((_b = state.auth.firebaseUser) === null || _b === void 0 ? void 0 : _b.uid) || '';
    const avaId = ((_c = state.userProfile.parse) === null || _c === void 0 ? void 0 : _c.avaId) || '';
    if (!v1Socket)
        return;
    return new JoinConversationManager(v1Socket, dispatch, avaName, firebaseUser, avaId);
});
export const joinConversationAnonymously = createAsyncThunk('conversation/joinConversationAnonymously', (avaName, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    if (avaName.length < 3)
        return;
    const state = getState();
    dispatch(setLoading(true));
    const avaId = selectAvaId(state);
    const joinConversationManager = selectJoinConversationManager(state);
    if (!avaId) {
        localStorage.setItem('ava_request', avaName);
        authInstance.signInAnonymously();
        segment.track('Joined Conversation', {
            HostAvaName: avaName,
            'Join Method': 'Room Name',
        });
        // ava_request will be picked up during v1 Socket creation.
    }
    else {
        // user is already logged in, so the v1 Socket already exists
        joinConversationManager === null || joinConversationManager === void 0 ? void 0 : joinConversationManager.handleJoinAvaRoom(avaName);
    }
}));
