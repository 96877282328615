// Labels are clickable, but users can always just click the radio button.
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Collapse, List, ListItemIcon } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Toggle from 'react-toggled';
import CustomGroupIcon from '../../../assets/icons/custom-group.svg';
import GroupSettingsIcon from '../../../assets/icons/group-settings.svg';
import GroupSettingsIconWhite from '../../../assets/icons/group-settings-white.svg';
import ReadOnlyIcon from '../../../assets/icons/read-only.svg';
import { useV1Socket } from '../../hooks/useV1Socket';
import { selectIsInConversation } from '../../selectors/conversation';
import { selectIsHost, selectStatusConversationMode } from '../../selectors/legacy-conversation';
import { selectSubscription } from '../../selectors/userProfile';
import { useAppSelector } from '../../store/store';
import { sendConversationModeUpdateMessage } from '../../utils/ws-v1';
const ConversationModeSelector = React.memo(function ConversationModeSelector() {
    const theme = useTheme();
    const { t } = useTranslation();
    const [selectedMode, setSelectedMode] = useState(undefined);
    const [selectTimeout, setSelectTimeout] = useState(undefined);
    const subscription = useAppSelector(selectSubscription);
    const isHost = useAppSelector(selectIsHost);
    const isInConversation = useAppSelector(selectIsInConversation);
    const conversationMode = useAppSelector(selectStatusConversationMode);
    const [ws] = useV1Socket();
    const handleChange = (conversationMode) => {
        if (ws)
            sendConversationModeUpdateMessage(ws, conversationMode);
        // If a new mode was selected in the last 5 seconds, show it to create
        // a quick transition. After 5 seconds let's return to what the backend
        // returns to show user the true state.
        setSelectedMode(conversationMode);
        if (selectTimeout) {
            clearTimeout(selectTimeout);
            setSelectTimeout(undefined);
        }
        setSelectTimeout(setTimeout(() => {
            setSelectedMode(undefined);
        }, 5000));
    };
    if (!isInConversation) {
        return null;
    }
    const modeToShow = selectedMode || conversationMode;
    const sufficientSubscription = subscription &&
        subscription.ongoingSubscription &&
        subscription.ongoingSubscription !== 'Free' &&
        subscription.ongoingSubscription !== 'Freedom' &&
        subscription.ongoingSubscriptionSubtype !== 'Lite';
    return isHost && sufficientSubscription ? (React.createElement(Toggle, null, ({ on, getTogglerProps }) => [
        React.createElement(ListItem, Object.assign({ button: true }, getTogglerProps(), { key: "header" }),
            React.createElement(ListItemIcon, null,
                React.createElement("img", { src: theme.palette.mode === 'dark' ? GroupSettingsIconWhite : GroupSettingsIcon, alt: "Conversation Settings Icon" })),
            React.createElement(ListItemText, null, t('conversation.conversationMode.name')),
            React.createElement(ListItemIcon, { style: { marginRight: 0, minWidth: 0 } }, on ? React.createElement(ExpandLessOutlinedIcon, null) : React.createElement(ExpandMoreOutlinedIcon, null))),
        React.createElement(Collapse, { in: on, key: "toggle_collapse" },
            React.createElement(List, { style: { paddingLeft: 10 } },
                React.createElement(ListItem, null,
                    React.createElement(RadioGroup, { "aria-label": "conversation-mode", name: "mode", value: modeToShow },
                        React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                            React.createElement("div", { style: { display: 'flex', alignItems: 'center', cursor: 'pointer' }, onClick: () => handleChange('public') },
                                React.createElement(Radio, { size: "small", style: { color: theme.palette.ava.blue }, value: "public" }),
                                React.createElement(ListItemIcon, { style: { minWidth: 24 } },
                                    React.createElement("img", { src: CustomGroupIcon, alt: "Interactive Mode Icon" })),
                                React.createElement(ListItemText, null, t('conversation.conversationMode.interactiveMode'))),
                            React.createElement(ListItemText, { style: { marginLeft: 10, marginTop: -10 }, secondary: t('conversation.conversationMode.interactiveDesc') })),
                        React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                            React.createElement("div", { style: { display: 'flex', alignItems: 'center', cursor: 'pointer' }, onClick: () => handleChange('public-muted') },
                                React.createElement(Radio, { size: "small", style: { color: theme.palette.ava.blue }, value: "public-muted" }),
                                React.createElement(ListItemIcon, { style: { minWidth: 24 } },
                                    React.createElement("img", { src: ReadOnlyIcon, alt: "Read Only Icon" })),
                                React.createElement(ListItemText, null, t('conversation.conversationMode.readOnlyMode'))),
                            React.createElement(ListItemText, { style: { marginLeft: 10, marginTop: -10 }, secondary: t('conversation.conversationMode.readOnlyDesc') })))))),
    ])) : null;
});
export default ConversationModeSelector;
