import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        gap: 8,
        cursor: 'pointer',
        background: theme.palette.ava.blue2,
        color: 'white',
        padding: '4px 16px',
        borderRadius: 12,
        fontSize: 16,
        fontWeight: 400,
    },
}));
export function ConvoV2FollowTranscriptButton(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    return (React.createElement(Box, { className: classNames(classes.root, props.className), onClick: props.onClick },
        React.createElement(Typography, null, t('ccMode.conversation.newTranscripts')),
        React.createElement(Typography, null, "\u2193")));
}
