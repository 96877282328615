import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { selectLang, selectNeighborTranscriptAuthors, selectSpeakersMap, selectTranscript, } from '../../selectors/legacy-conversation';
import { selectAvaId } from '../../selectors/userProfile';
import { useAppSelector } from '../../store/store';
import { getText } from '../../utils/scribeUtils';
import { combineParsedWords, parseWord } from '../../utils/transcripts';
import { ConvoV2ParticipantAvatar } from './ConvoV2ParticipantAvatar';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        gap: 24,
        flexDirection: 'row',
        justifyContent: 'stretch',
        width: '100%',
    },
    rootHidden: {
        transform: 'scaleY(0)',
    },
    rootAnimated: {
        transition: 'transform 0.3s ease-in-out',
    },
    firstInGroup: {
        marginTop: 8,
    },
    lastInGroup: {
        marginBottom: 8,
    },
    avatarContainer: {
        flexGrow: 0,
        marginBottom: 0,
        minWidth: 48,
        fontSize: 28,
    },
    hiddenAvatar: {
        visibility: 'hidden',
    },
    texts: {
        flexGrow: 1,
        gap: 4,
        backgroundColor: 'white',
        transition: 'background-color 2s ease',
        padding: '0 24px',
        marginRight: 16,
        justifyContent: 'space-between',
    },
    boldWord: {
        fontWeight: 1000,
    },
    disabledWord: {
        opacity: 0.5,
        textDecoration: 'line-through',
    },
    firstTextsInGroup: {
        paddingTop: 16,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
    },
    lastTextsInGroup: {
        paddingBottom: 8,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
    },
    textsFreshAnimated: {
        backgroundColor: theme.palette.ava.orange6,
        transition: '',
    },
    userNameText: {
        fontWeight: '600',
        fontSize: 24,
        color: (props) => props.speakerTextColor,
    },
    transcriptText: {
        fontWeight: '400',
        fontSize: 24,
        paddingTop: 8,
        color: (props) => props.speakerTextColor,
    },
}));
export function ConvoV2TranscriptItem({ transcriptId, animate }) {
    var _a, _b;
    const { t } = useTranslation();
    const transcript = useAppSelector((state) => selectTranscript(state, transcriptId));
    const { previousTranscriptAuthor, nextTranscriptAuthor } = useAppSelector((state) => selectNeighborTranscriptAuthors(state, transcriptId));
    const isFirstInGroup = previousTranscriptAuthor !== transcript.author;
    const isLastInGroup = nextTranscriptAuthor !== transcript.author;
    const speakers = useAppSelector(selectSpeakersMap);
    const lang = useAppSelector(selectLang);
    const avaId = useAppSelector(selectAvaId);
    const [fresh, setFresh] = useState(false);
    const [show, setShow] = useState(false);
    const speaker = speakers[transcript.author];
    const isSpeakerCurrentUser = transcript.author === avaId;
    const speakerTextColor = ((_b = (_a = speaker === null || speaker === void 0 ? void 0 : speaker.theme) === null || _a === void 0 ? void 0 : _a.light_theme) === null || _b === void 0 ? void 0 : _b.text_color) || 'white';
    const classes = useStyles({ speakerTextColor });
    const transcriptText = getText(transcript, lang);
    useEffect(() => {
        if (!animate)
            return;
        setFresh(true);
        const timeout = setTimeout(() => {
            setFresh(false);
        }, 2000);
        return () => {
            clearTimeout(timeout);
        };
    }, [transcriptText]);
    useEffect(() => {
        setShow(true);
    }, []);
    if (!transcriptText)
        return null;
    // Combine words with the same style so we minimize rendered DOM nodes (most
    // cases there should be just one big text)
    const transcriptSpans = combineParsedWords(transcriptText.split(' ').map(parseWord));
    const rootClassNames = [
        classes.root,
        !show && classes.rootHidden,
        animate && classes.rootAnimated,
        isFirstInGroup && classes.firstInGroup,
        isLastInGroup && classes.lastInGroup,
    ];
    const textsClassNames = [
        classes.texts,
        animate && fresh && classes.textsFreshAnimated,
        isFirstInGroup && classes.firstTextsInGroup,
        isLastInGroup && classes.lastTextsInGroup,
    ];
    return (React.createElement(Box, { className: classNames(rootClassNames) },
        React.createElement(Box, { className: classes.avatarContainer }, isFirstInGroup && speaker && React.createElement(ConvoV2ParticipantAvatar, { speaker: speaker, size: 48 })),
        React.createElement(Box, { className: classNames(textsClassNames) },
            isFirstInGroup && (React.createElement(Typography, { className: classes.userNameText }, isSpeakerCurrentUser ? t('transcripts.you') : speaker === null || speaker === void 0 ? void 0 : speaker.userName)),
            React.createElement(Typography, { className: classes.transcriptText }, transcriptSpans.map((span, index) => {
                return (React.createElement(React.Fragment, { key: index },
                    React.createElement("span", { className: classNames({
                            [classes.boldWord]: span.bold,
                            [classes.disabledWord]: span.disabled,
                        }) }, span.text),
                    ' '));
            })))));
}
