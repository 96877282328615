import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 18,
        padding: '8px 20px',
        width: '100%',
        justifyContent: 'start',
    },
    rootActive: {
        background: theme.palette.ava.transparent.blue[theme.palette.mode],
        color: theme.palette.ava.blue2,
    },
    icon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 40,
        height: 40,
    },
}));
export function ConvoV2LeftSidebarItem({ iconPath, iconComponent, text, expanded, active, onClick }) {
    const classes = useStyles();
    return (React.createElement(Button, { className: classNames(classes.root, { [classes.rootActive]: active }), onClick: onClick },
        React.createElement(Box, { className: classes.icon }, iconPath ? React.createElement("img", { src: iconPath, alt: text }) : iconComponent),
        expanded && text));
}
