import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { ConvoV2ConversationLinkButton } from './ConvoV2ConversationLinkButton';
import { ConvoV2ParticipantListBadge } from './ConvoV2ParticipantListBadge';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
        gap: 16,
        alignItems: 'center',
        width: '100%',
        flexShrink: 0,
        height: 80,
        padding: '0px 40px',
        backgroundColor: theme.palette.ava.white,
        borderLeft: `1px solid ${theme.palette.ava.grey7}`,
        borderBottom: `1px solid ${theme.palette.ava.grey7}`,
    },
}));
export function ConvoV2ConversationHeader() {
    const classes = useStyles();
    return (React.createElement(Box, { className: classes.root },
        React.createElement(ConvoV2ParticipantListBadge, null),
        React.createElement(ConvoV2ConversationLinkButton, null)));
}
