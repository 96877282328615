import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '56px',
        minWidth: '56px',
        height: '56px',
        border: '1px solid rgba(0, 0, 0, 0.2)',
        marginX: 1,
        borderRadius: '50%',
        padding: 0,
    },
}));
export function ConvoV2ControlBarButton({ children, className, onClick }) {
    const classes = useStyles();
    return (React.createElement(Button, { onClick: onClick || (() => { }), className: classNames(classes.root, className) }, children));
}
