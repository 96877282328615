import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { scribeCreateTranscript } from '../../actions';
import { selectAvaId } from '../../selectors/userProfile';
import { updateKeyboardInputShown } from '../../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../../store/store';
export function ConvoV2KeyboardInput({ open }) {
    const dispatch = useAppDispatch();
    const [value, setValue] = useState('');
    const avaId = useAppSelector(selectAvaId);
    return (React.createElement(Box, { sx: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            height: open ? 96 : 0,
            marginTop: open ? 3 : 0,
            flexShrink: 0,
            width: '100%',
            borderTopLeftRadius: '36px',
            borderTopRightRadius: '36px',
            backgroundColor: 'white',
            boxShadow: '2px 2px 10px 0px rgba(0,0,0,0.1)',
            padding: open ? 3 : 0,
            overflow: 'hidden',
            transition: 'transform 0.3s ease-in-out',
            transform: open ? 'translateY(0%)' : 'translateY(100%)',
        } },
        React.createElement(Box, { sx: {
                marginRight: 2,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            } },
            React.createElement(Button, { sx: {
                    minWidth: '20px',
                }, onClick: () => {
                    dispatch(updateKeyboardInputShown(false));
                } },
                React.createElement(ClearIcon, null))),
        React.createElement(Box, { sx: {
                flexGrow: 1,
            } },
            React.createElement(TextField, { InputProps: {
                    style: {
                        borderRadius: '24px',
                    },
                }, multiline: true, maxRows: 2, size: "small", fullWidth: true, value: value, onChange: (e) => {
                    setValue(e.target.value);
                }, placeholder: "Type" })),
        React.createElement(Box, { sx: {
                marginLeft: 2,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            } },
            React.createElement(Button, { sx: {
                    minWidth: '20px',
                }, onClick: () => {
                    // @ts-ignore
                    dispatch(scribeCreateTranscript(undefined, value, avaId));
                    dispatch(updateKeyboardInputShown(false));
                    setValue('');
                } },
                React.createElement(SendIcon, null)))));
}
