import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import MicImage from '../../../assets/images/convov2-mic.svg';
import { CONVOV2_CONVERATION_PAGE_ROUTE } from './constants';
import { ConvoV2PrimaryButton } from './ConvoV2PrimaryButton';
const useStyles = makeStyles(() => ({
    button: {
        padding: '16px 37px',
    },
    icon: {
        position: 'relative',
        marginLeft: -6,
        width: 24,
        height: 24,
    },
}));
export function ConvoV2NewSessionButton({ className }) {
    const classes = useStyles();
    const { t } = useTranslation();
    return (React.createElement(Link, { className: classNames(className), to: CONVOV2_CONVERATION_PAGE_ROUTE },
        React.createElement(ConvoV2PrimaryButton, { className: classes.button },
            React.createElement("img", { className: classes.icon, src: MicImage }),
            t('convoV2.sidebar.newSession'))));
}
